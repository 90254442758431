<div id="quad-wrapper">
  <video id="videoTopLeft" width="100%" height="100%" autoplay muted loop>
    <source src="assets/videos/doctors3.mp4" type="video/mp4">
  </video>
  <video id="videoTopRight" width="100%" height="100%" autoplay muted loop>
    <source src="assets/videos/doctors2.mp4" type="video/mp4">
  </video>
  <video id="videoBottomLeft" width="100%" height="100%" autoplay muted loop>
    <source src="assets/videos/doctors1.mp4" type="video/mp4">
  </video>
  <iframe width="100%" height="100%" src="https://conf-sample.caremessenger.co.uk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

  </iframe>
</div>
