<div class="call-viewer-container">
  <div class="dial-container" *ngIf="!showVideo">
    <app-avatar-thumbnail
      [user]="remoteUser"
      [hidePresence]="false"
    ></app-avatar-thumbnail>

    <div class="user-label">
      <h4>
        {{ remoteUser.first_name }} {{ remoteUser.last_name }} ({{
        remoteUser.username
        }})
      </h4>
    </div>

    <div class="call-buttons">

      <div id="auto-accept-container">
        <input
          type="checkbox"
          name="autoAccept"
          [checked]="autoAccept"
          (change)="toggleAutoAccept()"
          *ngIf="!callInProgress"/>
        <label for="autoAccept" *ngIf="!callInProgress" style="margin-left: 10px"> Auto Accept</label>
      </div>
      <div id="accept-after-container">
        <label for="acceptAfter" *ngIf="!callInProgress" style="margin-right: 10px">Start Call After </label>
        <select
          name="acceptAfter"
          *ngIf="!callInProgress"
          (change)="changeAcceptAfter($event)"
          [attr.disabled] = "autoAccept ? 'disabled' : null">>
          <option value="0">Call Accepted</option>
          <option value="5">5 Seconds</option>
          <option value="10">10 Seconds</option>
          <option value="15">15 Seconds</option>
        </select>
      </div>

      <div id="isrecorded-container">
      <input
        type="checkbox"
        name="isrecorded"
        [checked]="isRecorded"
        (change)="toggleIsRecorded()"
        *ngIf="!callInProgress"/>
        <label for="isrecorded" *ngIf="!callInProgress" style="margin-left: 10px"> Record</label>
      </div>
      <button
        id="video-call-button"
        (click)="requestVideoCall()"
        *ngIf="!callInProgress"
      >
        Video Call
      </button>
        <button
        (click)="endCall()"
        *ngIf="callInProgress"
      >
        Hang Up
      </button>
    </div>

    <h3 *ngIf="statusMessage" class="status-message">
      Status: {{ statusMessage }}
    </h3>
    <button
      (click)="abortCall()"
    >
      Back
    </button>
  </div>

  <div class="video-container" *ngIf="callInProgress && showVideo">
    <div #remoteMediaContainer id="remote-media-container"></div>
    <div
      #localMediaContainer
      id="local-media-container"
      [ngClass]="{ muted: !localVideoEnabled }"
    ></div>

    <div class="call-controls">
      <button
        (click)="toggleLocalAudioMuted()"
        [ngClass]="{ enabled: localAudioEnabled, disabled: !localAudioEnabled }"
        class="audio-video-button"
      >
        Mute Audio
      </button>

      <button (click)="endCall()" class="hang-up-button">
        Hang Up
      </button>

      <button
        (click)="toggleLocalVideoMuted()"
        [ngClass]="{ enabled: localVideoEnabled, disabled: !localVideoEnabled }"
        class="audio-video-button"
      >
        Mute Video
      </button>
    </div>
  </div>
</div>

<audio #callWaitingSound loop="true">
  <source src="assets/sounds/on-hold-ringtone.wav" type="audio/wav"/>
</audio>
<audio #callAcceptedSound>
  <source src="assets/sounds/bleep.mp3" type="audio/mp3"/>
  <source src="assets/sounds/bleep.wav" type="audio/wav"/>
</audio>
<audio #callDeclinedSound>
  <source src="assets/sounds/pling.wav" type="audio/wav"/>
</audio>
